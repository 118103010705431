@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .progress-bar-clip-path {
    clip-path: url(#progressbar-clippath)
  }
}

@font-face {
  font-family: "Figgins Sans V3";
  src: url("assets/fonts/FigginsSansV3.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Figgins Sans V3 Bold";
  src: url("assets/fonts/FigginsSansV3-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "William Regular A";
  src: url("assets/fonts/WilliamRegularA.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "William Regular Italic";
  src: url("assets/fonts/WilliamRegularA-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Pure_dating Regular";
  src:  url("assets/fonts/Pure_dating-Regular.otf") format("opentype"),
    url("assets/fonts/Pure_dating-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  background: #FFFFFF;
  margin: 0;
  font-family: "Figgins Sans V3", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div#root {
  height: 100%;
}

html, body {
  height: 100%;
}

.cover-nv107 {
  background: rgb(255, 0, 255);
}

.cover-nv96 {
  background: rgb(28, 23, 24);
}

.cover-nv91 {
  background: rgb(250, 177, 163);
}

@keyframes pulse {
  to {
      box-shadow: 0 0 0 8px transparent;
      transform: scale(101%);
  }
}

.pulse-animation {
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  transform: scale(1);
  transition: transform .1s ease-in-out;
  animation: pulse 1.4s cubic-bezier(.75, 0, 0, 1) infinite;
}