.leaflet-pane.leaflet-tile-pane, .leaflet-bottom, .leaflet-top {
  filter: grayscale(100%) invert(100%);
}

.photo-marker_container, .photo-marker_photo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-marker_photo {
  z-index: 201;
  transform: scale(0.55);
  transition: all 0.3s ease-in;
}

.photo-marker_border {
  position: absolute;
}

.photo-marker_mask {
  position: absolute;
  transform: scale(0.98);
}

.photo-marker_arrow {
  transition: all 0.3s ease-in;
  position: absolute;
}

.photo-marker_text {
  position: absolute;
  z-index: 200;
  top: 28px;
  opacity: 0;

  font-size: 14px;
  line-height: 18px;
  color: white;

  transition: all 0.3s ease-in;
}

@media only screen and (min-width: 1024px) {
  .photo-marker_container:hover > .photo-marker_photo {
    transform: none;
  }

  .photo-marker_container:hover > .photo-marker_arrow {
    transform: scaleY(0.5);
  }

  .photo-marker_container:hover .photo-marker_text {
    opacity: 1;
  }
}

.photo-marker_container__opened > .photo-marker_photo {
  transform: none;
}

.photo-marker_container__opened > .photo-marker_arrow {
  transform: scaleY(0.5);
}

.photo-marker_container__opened .photo-marker_text {
  opacity: 1;
}
